//development api
//const mainApi = "https://audit.dev-des-oies.com"

// production api
const mainApi = "https://admin.audit-en.les-oies-du-cyber.com/"

export const prefixApi = `${mainApi}/api/`
export const fileApi = `${mainApi}/uploads/resources/`
export const prefixFileApi = `${mainApi}/pdf`
export const prefix_api_set_img = `${mainApi}/uploads/profile_images/`
export const prefix_api_set_img_content = `${mainApi}/uploads/content/`
export const prefixApi2 = `${mainApi}/api/`

export const prefixHeaderObj = {
  Authorization: "Basic " + btoa("api:P4r0|@876!api"),
  "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  Accept: "application/json, text/javascript",
}

export const prefixHeaderAuth = {
  Authorization: "Basic " + btoa("api:P4r0|@876!api"),
}
